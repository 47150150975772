@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.why-visalex-section {
  position: relative;
  z-index: 10;
  padding-bottom: 100px;
  background: linear-gradient(0deg, $darken-blue 0%, #070f5f 100%);
  color: $white;

  &__content {
    text-align: center;
    padding: 135px 0 70px;
    margin: 0 -15px;

    h2 {
      margin-bottom: 14px;
      letter-spacing: 1.5px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .why-visalex-section {
    p {
      font-size: 18px;
      line-height: 30px;
    }

    &__benefits {
      padding: 0 5%;

      .benefit {
        margin-left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .why-visalex-section {
    h3 {
      font-size: 35px;
      font-weight: 300;
      line-height: 45px;
    }

    :global(.container) {
      padding: 0;
    }

    &__content {
      margin: 0 -15px;
    }
  }
}

@media screen and (max-width: 576px) {
  .why-visalex-section {
    &__content {
      margin: 0 auto;
      padding: 90px 20px;
    }
  }
}
