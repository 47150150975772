@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.benefit {
  color: $gray-800;
  margin-bottom: 70px;

  img {
    width: 90px;
  }

  h5 {
    margin: 12px 0 2px;
    font-family: $display-font-family;
    font-size: 26px;
    font-weight: bold;
    line-height: 45px;
    letter-spacing: 1px;
  }
}
