@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.testimonials {
  position: relative;
  overflow: hidden;
  background: $section-bg;

  &__img {
    width: 30%;

    &_sidebar {
      width: 50%;
    }
  }
}

.testimonials__CTA {
  h6 {
    color: $gray-blue;
    font-size: 24px;
    font-style: italic;
    line-height: 40px;
    margin: 10px 0 30px;
  }

  &_sidebar {
    margin: 0;
    padding: 30px;
    border: 1px solid $white-blue;
    box-sizing: border-box;
    border-radius: 5px;

    h2 {
      font-size: 21px;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 5px;
    }

    h6 {
      color: $gray-blue;
      font-size: 16px;
      line-height: 145%;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
    }
  }
}

@include media-breakpoint-up(md) {
  .testimonials {
    :global(.container) {
      width: calc(100vw - 240px);
      padding: 0 20px;
      max-width: 1200px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .testimonials {
    :global(.container) {
      width: 100%;
    }

    .testimonials {
      &__img {
        width: 50%;
      }
    }
  }

  .testimonials__CTA h2 {
    font-size: 37px;
    line-height: 57px;
    letter-spacing: 1px;
    margin: 0;
  }

  .testimonials__CTA h6 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
  }
}

@include media-breakpoint-down(xs) {
  .testimonials {
    &__img {
      width: 70%;
    }
  }
}
