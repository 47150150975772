@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.testimonials__slide {
  padding: 0 10px;
}

.testimonials__card {
  max-width: 390px;
  margin: auto;
  background-color: $white;
  border-radius: 4px;
  padding: 45px;
  box-shadow: 0 1px 3px 0 rgba(5, 27, 43, 0.14);

  .testimonials__card-header {
    display: flex;
    padding-bottom: 25px;

    img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      margin-right: 20px;
    }

    h6 {
      font-size: 20px;
      margin-bottom: 6px;
    }

    small {
      color: $gray-blue;
      font-family: $display-font-family;
      font-size: 17px;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: 1.5px;
      white-space: pre-line;
    }
  }

  p {
    color: $gray-800;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }
}

.testimonials__CTA--padding {
  padding: 135px 0;
}

@include media-breakpoint-down(lg) {
  .testimonials__card {
    .testimonials__card-header {
      padding-bottom: 5px;

      img {
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
    }
  }
}
