@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.native-language {
  position: relative;

  &__content {
    h3 {
      line-height: 45px;
      margin-bottom: 20px;
    }
  }

  &__images {
    display: flex;

    &-holder {
      margin: auto;
      padding: 110px 20px 110px 50px;
      width: 100%;
    }

    &-imac {
      padding: 80px 140px 80px 100px;
      width: 100%;
    }

    &-hola,
    &-hi,
    &-ola {
      position: absolute;
      opacity: 0;
      transform: scale(0);
    }

    &-hi {
      top: 355px;
      width: 80px;
      left: 45px;
    }

    &-hola {
      width: 160px;
      left: 50%;
      margin-left: -100px;
      top: 120px;
      animation-delay: 0.5s;
    }

    &-ola {
      width: 125px;
      right: 20px;
      top: 110px;
      animation-delay: 0.25s;
    }

    &--visible {
      .native-language__images-hola,
      .native-language__images-hi,
      .native-language__images-ola {
        animation-name: expand-bounce;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
      }
    }
  }
}

@keyframes expand-bounce {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@include media-breakpoint-down(lg) {
  .native-language {
    &__images {
      height: 600px;

      &-holder {
        position: absolute;
        width: 620px;
        left: 50%;
        margin-left: -310px;
        padding: 0 20px 0 50px;
        transform: scale(0.75);
        transform-origin: 50% 50%;
      }

      &-hola {
        top: 0;
      }
    }

    &__content {
      text-align: center;
    }
  }
}

@include media-breakpoint-down(md) {
  .native-language {
    &__images {
      height: 400px;

      &-holder {
        margin-left: -230px;
        transform: scale(0.5);
        transform-origin: 25% 25%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .native-language {
    margin: auto;

    &__images {
      height: 400px;

      &-holder {
        margin-left: -150px;
        transform: scale(0.5);
        transform-origin: 0% 25%;
      }
    }
  }
}
