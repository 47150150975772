@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.default-hero {
  background: #070f5f;

  :global(.hero__background) {
    background: url('/images/pattern_stars_and_srtripes_large.png') center center repeat;
  }
}

.hero-section {
  padding: 100px 0 160px;

  @extend .default-hero;

  &__leftside,
  &__rightside {
    position: relative;
    z-index: 20;

    img {
      margin-top: 5px;
      width: auto;
    }
  }

  .hero-section__leftside {
    text-align: left;
    padding: 0 0 0 15px;
    flex: 1;

    p {
      font-family: 'PT Serif', sans-serif;
      font-style: italic;
    }
  }

  &__rightside {
    display: flex;
    justify-content: center;
    padding-right: 60px;
    position: relative;

    img {
      position: absolute;
      height: 860px;
      bottom: 0;
      right: -60px;
    }
  }

  &__CTA {
    &-btn {
      border: none !important;
      box-shadow: none !important;
      display: flex;
      color: $white;
      position: relative;
      padding-left: 100px;
      height: 60px;
      margin-top: 20px;

      span {
        color: $white;
        font-size: 18px;
        margin: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        transition: border-bottom-color 0.3s ease-out;
      }

      img {
        width: 56px;
        height: 56px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -28px;
        transition: opacity 0.3s ease-out;
      }

      &--normal {
        opacity: 0.9;
      }

      &--hover {
        opacity: 0;
      }

      &:hover {
        .hero-section__CTA-btn--normal {
          opacity: 1;
        }

        span {
          border-bottom-color: rgba(255, 255, 255, 0.5);
        }
      }

      &:focus {
        .hero-section__CTA-btn--normal {
          opacity: 0.3;
        }

        .hero-section__CTA-btn--hover {
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-section {
    &__leftside {
      h1 {
        font-size: 70px;
        line-height: 88px;
      }

      p {
        font-size: 22px;
        font-family: 'PT Serif', sans-serif;
        font-style: italic;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .default-hero {
    :global(.hero__background) {
      background: url('/images/pattern_stars_and_srtripes_small.png') center center repeat;
    }
  }

  .hero-section {
    // padding: 80px 100px 140px;

    &__leftside,
    &__rightside {
      padding: 0;
    }

    &__leftside {
      p {
        margin: 0;
      }
    }

    &__rightside {
      padding-right: 0;

      img {
        height: 750px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .default-hero {
    :global(.hero__background) {
      background: url('/images/pattern_stars_and_srtripes_xsmall.png') center center repeat;
    }
  }

  .hero-section {
    padding: 350px 30px 100px;

    &__row {
      align-items: center;
    }

    &__leftside {
      order: 2;
      padding: 25px 15px 10px !important;

      h1,
      p {
        text-align: center;
      }
    }

    &__rightside {
      order: 1;
      height: auto;

      img {
        width: 80%;
        height: auto;
        right: 50px;
      }
    }

    &__CTA-btn {
      margin: auto;
      white-space: nowrap;
      padding-top: 70px;
      height: 130px;
      padding-left: 20px;

      img {
        margin-top: 0;
        left: 50%;
        margin-left: -28px;
        top: 10px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .hero-section {
    padding: 177px 0 100px;

    &__rightside {
      img {
        max-height: 450px;
        bottom: -10px;
        right: 35px;
      }
    }
  }
}
