@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.how-we-help {
  position: relative;
  z-index: 20;
  padding-bottom: 100px;

  &__cards {
    position: relative;
  }

  &__content {
    text-align: center;
    padding: 100px 0 70px;
    margin: 0 -15px;

    h2 {
      margin-bottom: 14px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .how-we-help {
    p {
      font-size: 18px;
      line-height: 30px;
    }

    &__cards {
      margin: 0 auto;
    }

    &__content {
      padding: 130px 0 70px;
    }
  }
}

@include media-breakpoint-down(md) {
  .how-we-help {
    padding-bottom: 0;

    &__content {
      margin: 0 -15px 70px;
      padding: 30px 0 70px;
    }

    &__cards {
      margin: 0 -15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .how-we-help {
    &__content {
      margin: 0 auto 70px;
    }
  }
}
