@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.statistics {
  position: relative;
  padding-bottom: 120px;
  border-bottom: 1px solid #444d8b;
  margin-bottom: 30px;

  &__holder {
    display: flex;
    flex-wrap: wrap;
    padding-left: 60px;

    :global(.d-flex) {
      position: relative;
      z-index: 1;
    }

    &:hover {
      :global(.blurred-ellipse-bg) {
        opacity: 0.6;
      }
    }
  }

  &__topic {
    margin-bottom: 60px;
    padding: 0;

    img {
      height: 90px;
      margin-bottom: 12px;
    }

    h4 {
      color: $white;
      font-size: 66px;
      font-weight: 100;
      line-height: 70px;
      letter-spacing: 0;
      margin-bottom: 5px;
    }

    h5 {
      color: $light-blue;
      letter-spacing: 2.3px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }
  }
}

.statistics :global(.blurred-ellipse-bg) {
  position: absolute;
  z-index: 0;
  width: 200%;
  height: 200%;
  max-width: 100vw;
  left: -70%;
  top: -70%;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: 110% 110%;
  -webkit-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

@include media-breakpoint-up(lg) {
  .statistics :global(.blurred-ellipse-bg) {
    background-image: url(/images/blurred_ellipse.png);
  }
}

@include media-breakpoint-down(lg) {
  .statistics {
    &__topic {
      text-align: center;
      padding: 0 20px;

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 60px;
      }
    }

    &__holder {
      padding-left: 15px;
    }

    &__content {
      text-align: center;
      margin-bottom: 70px;
    }

    &__bg {
      left: -50% !important;
    }
  }
}

@include media-breakpoint-down(xs) {
  .statistics {
    &__content {
      padding: 30px 50px !important;

      h3 {
        font-size: 30px !important;
      }

      p {
        margin-top: 8px;
      }
    }
  }
}
