@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.visa-card {
  display: flex;
  flex-direction: column;
  background: $white;
  margin-top: -75px;
  border-radius: 5px;
  overflow: hidden;
  cursor: default;
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px 0 rgba(5, 27, 43, 0.14);
  transform-origin: 50% 75px;
  will-change: transform, box-shadow;
  transition: box-shadow 0.5s ease-out, transform 0.3s ease-out;
  transform: scale(1) translateZ(0);

  &__header {
    height: 75px;
    display: flex;
    cursor: pointer;

    h4 {
      margin: auto;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      position: relative;

      a {
        color: $white !important;
      }
    }
  }

  &:hover {
    animation: cardAnimation 0.5s ease-in forwards;
    box-shadow: 0 30px 50px -10px rgba(5, 27, 43, 0.3);
    transform: scale(1.03);
  }

  &__content {
    width: 100%;
    margin: auto;
    padding: 24px 0;
    max-width: 370px;
    text-align: center;

    // Fix for "jumping text" on scale
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    p {
      color: $gray-800;
      font-size: 22px;
      line-height: 34px;
      font-weight: 300;
    }

    a {
      margin: 24px auto 10px;
    }

    small {
      color: #44535a;
      font-size: 16px;
      line-height: 30px;
    }
  }

  &.work-visa {
    .visa-card__header {
      background: $dark-blue;
    }
  }

  &.green-card {
    .visa-card__header {
      background: $green;
    }
  }
}

@include media-breakpoint-down(md) {
  .visa-card {
    transform-origin: 50% 55px;
    max-width: 550px;
    margin: -55px auto 0;

    &__header {
      height: 55px;
    }

    &__holder {
      padding: 0 0 105px;
    }

    p {
      padding: 0 15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .visa-card {
    &__holder {
      padding: 0 30px 105px;
    }
  }
}
