@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.progress {
  border-bottom: 1px solid #444d8b;

  h3 {
    margin-bottom: 24px;
  }

  &__leftside {
    padding: 100px 100px 100px 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__rightside {
    display: flex;
    height: 750px;

    .progress__circle {
      padding-top: 45px;
      width: 365px;
      margin-left: -60px;
      cursor: pointer;
      transform: rotate(-30deg);

      &-holder {
        margin: auto;

        h4 {
          font-size: 66px;
          transition: font-size 0.3s ease-in;
        }

        :global(.blurred-ellipse-bg) {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-size: 100% 100%;
          opacity: 0.4;
        }

        &:hover,
        &--active {
          :global(.blurred-ellipse-bg) {
            opacity: 0.6;
          }
        }

        &--active {
          h4 {
            font-size: 74px !important;
          }
        }
      }
    }
  }

  &__steps {
    &-step {
      opacity: 0.7;
      transition: opacity 0.3s ease-in;

      svg {
        height: 35px;
        margin: 0 auto 10px;
      }

      h6 {
        text-shadow: 1px 1px 1px $gray-800;
        white-space: pre-line;
      }

      &[data-key='3'] {
        svg {
          height: 45px;
          margin-bottom: 0;
        }
      }

      &[data-key='4'] {
        svg {
          height: 40px;
          margin-bottom: 4px;
        }
      }

      &::after {
        @include pseudo();

        opacity: 0;
        left: -5%;
        top: -45%;
        width: 100%;
        height: 100%;
        background-image: url('/icons/blurred_step_shadow.png');
        background-repeat: no-repeat;
        background-size: 110% 110%;
        transition: opacity 0.5s ease-in;
      }

      &--active {
        opacity: 1;

        &::after {
          opacity: 0.7;
        }
      }
    }
  }

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: -10px;
    margin-top: 25px;
    text-align: center;
    cursor: pointer;
    z-index: 1;

    h4 {
      color: #fff;
      font-size: 66px;
      font-weight: 100;
      line-height: 70px;
      letter-spacing: 0;
      margin-bottom: 5px;
      transition: font-size 0.3s ease-in;
    }

    h5 {
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}

$progress-line-height: 14px;

.line {
  position: relative;
  z-index: 2;
  width: calc(100% - 40px);
  overflow: hidden;
  border-radius: 8px;
  background: $darkest-blue;
  height: $progress-line-height;
  margin: 10px auto 20px;

  &::after {
    @include pseudo();

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/images/progress-dots.png) 80% no-repeat;
    background-size: 60%;
  }

  &__active-bar {
    background: $green;
    height: $progress-line-height;
    will-change: transform;
    position: relative;
    z-index: 3;
  }
}

@include media-breakpoint-down(lg) {
  .progress {
    &__leftside {
      margin: auto;
      padding: 60px 60px 10px;
      text-align: center;
    }

    &__rightside {
      height: 700px;
    }

    &__steps {
      margin-left: 20px;
    }
  }
}

@include media-breakpoint-up(md) {
  .progress {
    &__steps {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      &-step {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -80px;
        opacity: 0.7;
        transition: opacity 0.3s ease-in;

        h6 {
          text-shadow: 1px 1px 1px $gray-800;
          white-space: pre-line;
        }

        &[data-key='1'] {
          transform: translate(200px, -150px);
          text-align: center;
        }

        &[data-key='2'] {
          transform: translate(255px, 124px);
          text-align: center;
        }

        &[data-key='3'] {
          transform: translate(-15px, 300px);
          text-align: center;
        }

        &[data-key='4'] {
          width: 180px;
          transform: translate(-290px, 125px);
          text-align: center;
        }

        &[data-key='5'] {
          display: flex;
          flex-direction: column;
          transform: translate(-259px, -150px);

          svg {
            margin-bottom: 0;
          }

          h6 {
            text-align: center;
            padding-left: 20px;
            padding-top: 20px;
          }

          &::after {
            left: -5%;
          }
        }

        &--active {
          opacity: 1;

          &::after {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .progress {
    &__leftside {
      text-align: left;
      padding: 30px 0 0;

      h3 {
        margin-bottom: 10px;
      }

      p {
        line-height: 30px;
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .progress {
    .progress__leftside {
      padding: 30px 20px;
      text-align: center;
      margin: 0 auto;
    }

    &__rightside {
      width: 100vw;
      height: auto;
      padding: 30px 0;
    }

    &__circle {
      transform: rotate(-25deg);
      left: -20px;
      top: 15px;
      overflow: unset;

      &-holder {
        width: 100%;
      }
    }

    &__steps {
      height: 240px;
      display: flex;
      padding-top: 90px;
      margin: -90px auto 0;
      max-width: 330px;
      overflow: hidden;
      z-index: 2;

      :global(.slider__page) {
        height: 240px;
        position: absolute !important;
        top: 90px;
      }

      :global(.slider__controls) {
        width: 100%;
        left: 0;
        margin-left: 0;
        z-index: 2;
        margin-top: -20px;

        svg {
          width: 30px;
        }
      }

      &::before,
      &::after {
        @include pseudo();
        @include position('absolute', 40px, auto, 0, auto);

        width: 100px;
      }

      &::before {
        left: 0;
        background: linear-gradient(to right, #030b6e 40%, rgba(#030b6e, 0));
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, #030b6e 40%, rgba(#030b6e, 0));
      }

      &-step {
        will-change: transform, opacity;
        transition: none;
        position: absolute;
        width: 300px;
        height: 100%;
        left: 50%;
        margin-left: -150px;

        &::after {
          left: 50%;
          width: 200px;
          height: 150px;
          top: -67px;
          margin-left: -110px;
          z-index: 1;
        }

        &-content {
          max-width: calc(100% - 120px);
          position: relative;
          z-index: 2;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          h6 {
            margin-top: 12px;
            white-space: normal;
          }
        }
      }

      &-holder {
        position: relative;
        width: 100%;
      }
    }

    &__content {
      @include position(relative, auto, auto, auto, auto);

      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: calc(100% - 80px);
      margin: auto;
      transform: none;

      h5 {
        text-align: left;
        width: 120px;
        padding-left: 20px;
        font-size: 16px;
        line-height: 20px;
      }

      h4 {
        font-size: 50px !important;
        line-height: 60px;
      }
    }
  }

  .line {
    max-width: 300px;
  }
}
