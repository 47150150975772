@import '@visalex/core/src/styles/variables';
@import '@visalex/core/src/styles/mixins';

.video-modal {
  width: 100%;

  iframe {
    width: 100%;
    height: calc(80vh - 200px);
    max-height: 500px;
  }
}

@include media-breakpoint-down(xl) {
  .video-modal {
    max-width: 1000px;

    iframe {
      min-height: 460px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .video-modal {
    max-width: 600px;

    iframe {
      height: 40vh;
    }
  }
}
